<template>
    <div class="resource-center-preview">
        <!-- <headers></headers> -->
        <div class="tabs-box">
            <div class="tabs-left">
                <div>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:20pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:14pt; font-weight:bold">目</span><span
                            style="font-family:宋体; font-size:14pt; font-weight:bold">  </span><span
                            style="font-family:宋体; font-size:14pt; font-weight:bold">录</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:5pt">&#xa0;</span></p>
                    <p style="line-height:20pt; margin:0pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc9297"><span style="font-family:宋体; font-size:11pt">一、
                                项目简介</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc17652"><span
                                style="font-family:宋体; font-size:11pt">（一）项目概况</span></a>
                    </p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc22401"><span
                                style="font-family:宋体; font-size:11pt">（二）培训目标</span></a>
                    </p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc30829"><span
                                style="font-family:宋体; font-size:11pt">（三）培训对象</span></a>
                    </p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc10787"><span
                                style="font-family:宋体; font-size:11pt">（四）培训时间及地点</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc15618"><span
                                style="font-family:宋体; font-size:11pt">（五）培训内容</span></a>
                    </p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc7189"><span
                                style="font-family:宋体; font-size:11pt">（六）组织实施单位</span></a>
                    </p>
                    <p style="line-height:20pt; margin:0pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc18621"><span
                                style="font-family:宋体; font-size:11pt">二、项目实施情况</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc29263"><span
                                style="font-family:宋体; font-size:11pt">（一）学员报到，项目开班</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc32614"><span
                                style="font-family:宋体; font-size:11pt">（二）专题讲座，对话专家</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc12648"><span
                                style="font-family:宋体; font-size:11pt">（三）互动交流，答疑解惑</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc27520"><span
                                style="font-family:宋体; font-size:11pt">（四）入校访学，课例观摩</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc30993"><span
                                style="font-family:宋体; font-size:11pt">（五）交流研讨，同伴互助</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc22596"><span
                                style="font-family:宋体; font-size:11pt">（六）参观博馆，铭记历史</span></a></p>
                    <p style="line-height:20pt; margin:0pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc12453"><span
                                style="font-family:宋体; font-size:11pt">三、项目完成情况</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc23688"><span
                                style="font-family:宋体; font-size:11pt">（一）严格筹备准备，确保培训顺利实施</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc13566"><span
                                style="font-family:宋体; font-size:11pt">（二）结合实际制定方案，针对性培训</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc26025"><span
                                style="font-family:宋体; font-size:11pt">（三）落实保障，生活学习两手抓</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc31282"><span
                                style="font-family:宋体; font-size:11pt">（</span><span
                                style="font-family:宋体; font-size:11pt">四</span><span
                                style="font-family:宋体; font-size:11pt">）</span><span
                                style="font-family:宋体; font-size:11pt">小组任务驱动，同伴互助合作学习</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc2799"><span
                                style="font-family:宋体; font-size:11pt">（</span><span
                                style="font-family:宋体; font-size:11pt">五</span><span
                                style="font-family:宋体; font-size:11pt">）</span><span
                                style="font-family:宋体; font-size:11pt">结业仪式</span><span
                                style="font-family:宋体; font-size:11pt">，</span><span
                                style="font-family:宋体; font-size:11pt">展示收获，寄望未来</span></a></p>
                    <p style="line-height:20pt; margin:0pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc26590"><span
                                style="font-family:宋体; font-size:11pt">四、项目效益</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc29969"><span
                                style="font-family:宋体; font-size:11pt">（</span><span
                                style="font-family:宋体; font-size:11pt">一</span><span
                                style="font-family:宋体; font-size:11pt">）</span><span
                                style="font-family:宋体; font-size:11pt">培训内容符合当前教育发展新形势</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc11562"><span
                                style="font-family:宋体; font-size:11pt">（</span><span
                                style="font-family:宋体; font-size:11pt">二</span><span
                                style="font-family:宋体; font-size:11pt">）</span><span
                                style="font-family:宋体; font-size:11pt">培训形式以任务为驱动，激发教师参与热情</span></a></p>
                    <p style="line-height:20pt; margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><a
                            style="color:#000000" href="#_Toc17078"><span
                                style="font-family:宋体; font-size:11pt">（三）</span><span
                                style="font-family:宋体; font-size:11pt">项目培训反馈</span></a></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <a name="_Toc20675"></a><a name="_Toc31406"></a><a name="_Toc5994"></a><a name="_Toc6797"></a>
                    </p>
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0"><span
                            style="font-family:Calibri; font-size:16pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0"><span
                            style="font-family:Calibri; font-size:16pt; font-weight:bold">&#xa0;</span></p>
                </div>
            </div>
            <div class="tabs-right">
                <div>
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0"><span
                            style="font-family:宋体; font-size:16pt; font-weight:bold">绩效报告：</span><span
                            style="font-family:宋体; font-size:16pt; font-weight:bold">内蒙古中部</span><span
                            style="font-family:宋体; font-size:16pt; font-weight:bold">片区</span><span
                            style="font-family:宋体; font-size:16pt; font-weight:bold">统编三科教师</span><span
                            style="-aw-bookmark-end:_Toc20675"></span><span
                            style="-aw-bookmark-end:_Toc31406"></span><span
                            style="-aw-bookmark-end:_Toc5994"></span><span style="-aw-bookmark-end:_Toc6797"></span>
                    </p>
                    <p style="margin:0pt; orphans:0; text-align:center; widows:0"><a name="_Toc10046"></a><a
                            name="_Toc1950"></a><a name="_Toc19500"></a><a name="_Toc16258"><span
                                style="font-family:宋体; font-size:16pt; font-weight:bold">第一期线下</span><span
                                style="font-family:宋体; font-size:16pt; font-weight:bold">集中培训</span><span
                                style="-aw-bookmark-end:_Toc10046"></span><span
                                style="-aw-bookmark-end:_Toc1950"></span><span
                                style="-aw-bookmark-end:_Toc19500"></span></a></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">1、</span><a name="_Toc9297"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">项目简介</span></a></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc17652"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（一）项目概况</span></a></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">“三科统编教材”是党中央、国务院立足于党和国家全局做出的重要决策部署，统编三科教师培训意义非凡，不仅为铸牢中华民族共同体意识发挥重要作用，并且为推动内蒙古自治区基础教育科学发展奠定坚实基础。受教育部委托，</span><span
                            style="font-family:宋体; font-size:12pt">首都师范大学</span><span
                            style="font-family:宋体; font-size:12pt">牵头天津师范大学、河北师范大学、内蒙古师范大学、集宁师范学院，</span><span
                            style="font-family:宋体; font-size:12pt">按照“一校一方案、一师一计划”的精准培训目标，开展为期一年线上线下混合式在岗研修培训。</span>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc22401"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（二）培训目标</span></a></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">提高受训地区三科教师使用国家通用语言文字的思想认识和教
                            育教学能力，形成使用国家通用语言文字教学的氛围，将统编三科教
                            材的编写理念落实在课堂，用好新教材、教好新教材，提升课堂教学 能力。引导当地教师，尤其是民族教师了解并掌握基本的教育理念、教
                            育法规与教学方法，迅速适应教师角色，树立理想信念，并将职业目
                            标融入到国家与地方事业的发展中，成长为具有坚定信念、终身学习、 胜任课堂、改革创新的新教师。</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt 0pt 0pt 24pt; orphans:0; text-indent:0pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（3）</span><a
                            name="_Toc30829"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">培训对象</span></a></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">内蒙古中部四地区143位统编三科教师，呼和浩特34人、包头11人、乌兰察布29人、锡林郭勒盟69人。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt 0pt 0pt 24pt; orphans:0; text-indent:0pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（4）</span><a
                            name="_Toc10787"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">培训时间及地点</span></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">培训时间：2021年</span><span
                            style="font-family:宋体; font-size:12pt">4</span><span
                            style="font-family:宋体; font-size:12pt">月</span><span
                            style="font-family:宋体; font-size:12pt">10</span><span
                            style="font-family:宋体; font-size:12pt">日-2021年</span><span
                            style="font-family:宋体; font-size:12pt">4</span><span
                            style="font-family:宋体; font-size:12pt">月</span><span
                            style="font-family:宋体; font-size:12pt">16</span><span
                            style="font-family:宋体; font-size:12pt">日</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:normal">培训地点：北京</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc15618"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（五）培训内容</span></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">针对</span><span
                            style="font-family:宋体; font-size:12pt">内蒙古中部片区三科教师</span><span
                            style="font-family:宋体; font-size:12pt">的实际需求，围绕</span><span
                            style="font-family:宋体; font-size:12pt">统编三科教学</span><span
                            style="font-family:宋体; font-size:12pt">工作重难点问题，依据“逻辑递进、遵规求实”的原则进行培训内容设计。</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">培训内容安排集中专题培训（</span><span
                            style="font-family:宋体; font-size:12pt">7</span><span
                            style="font-family:宋体; font-size:12pt">天），包含专题指导、案例解析、参与式活动、分组研修、入校访学、成果分享等环节，深化理论指导实践，培训内容逐层递进，强调课程的实效性。同时充分发挥网络平台优势，在培训过程中提供网络支持手段，不断提升培训效果。</span><span
                            style="font-family:宋体; font-size:12pt">统编三科课程标准的理念、内容结构及学科的育人价值； </span><span
                            style="font-family:宋体; font-size:12pt">培训内容主要包含以下方面</span></p>
                    <ol type="1" style="margin:0pt; padding-left:0pt">
                        <li
                            style="font-family:宋体; font-size:12pt; line-height:150%; list-style-position:inside; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                                style="font-family:宋体; font-size:12pt">统编三科教材的研制背景、价值意义与教学理念； </span></li>
                        <li
                            style="font-family:宋体; font-size:12pt; line-height:150%; list-style-position:inside; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                                style="font-family:宋体; font-size:12pt">统编三科教材的设计思路、体例特点与内容结构；</span></li>
                        <li
                            style="font-family:宋体; font-size:12pt; line-height:150%; list-style-position:inside; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                                style="font-family:宋体; font-size:12pt">统编三科教材的教学重难点、教学方法及如何做好教学设计； </span></li>
                        <li
                            style="font-family:宋体; font-size:12pt; line-height:150%; list-style-position:inside; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                            <span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0; </span><span
                                style="font-family:宋体; font-size:12pt">课堂教学的案例分析及入校指导的实践任务。</span></li>
                    </ol>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">详细</span><span
                            style="font-family:宋体; font-size:12pt">培训内容如下所示：</span></p>
                    <div style="text-align:center">
                        <table cellspacing="0" cellpadding="0"
                            style="border-collapse:collapse; margin:0 auto; width:483.45pt">
                            <tr style="height:26.6pt">
                                <td colspan="2"
                                    style="background-color:#bfbfbf; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.3pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt; font-weight:bold">培训时间</span>
                                    </p>
                                </td>
                                <td
                                    style="background-color:#bfbfbf; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt; font-weight:bold">培训专题</span>
                                    </p>
                                </td>
                                <td
                                    style="background-color:#bfbfbf; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt; font-weight:bold">培训形式</span>
                                    </p>
                                </td>
                                <td
                                    style="background-color:#bfbfbf; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt; font-weight:bold">主讲人</span>
                                    </p>
                                </td>
                            </tr>
                            <tr style="height:26.6pt">
                                <td colspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:96.3pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月</span><span
                                            style="font-family:仿宋; font-size:10.5pt">10</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt; font-weight:normal">（星期六）</span>
                                    </p>
                                </td>
                                <td colspan="3"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:364.8pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span
                                            style="font-family:仿宋; font-size:10.5pt; font-weight:normal">学员报到、领取学习资料</span>
                                    </p>
                                </td>
                            </tr>
                            <tr style="height:31.8pt">
                                <td rowspan="3"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月</span><span
                                            style="font-family:仿宋; font-size:10.5pt">11</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">（星期日）</span></p>
                                </td>
                                <td rowspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td colspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:278.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">开班仪式</span><span
                                            style="font-family:仿宋; font-size:10.5pt">：</span><span
                                            style="font-family:仿宋; font-size:10.5pt">领导致辞</span><span
                                            style="font-family:仿宋; font-size:10.5pt">、培训简述</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">参训地区领导</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">项目组</span></p>
                                </td>
                            </tr>
                            <tr style="height:31.4pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">新课程、新教材、新教学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">专题讲座</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">案例教学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">付宜红</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">教育部课程中心</span></p>
                                </td>
                            </tr>
                            <tr style="height:26.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">统编教材教学、通用语言文字等领域的问题交流</span>
                                    </p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">工作坊研修</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">蔡可</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">首都师范大学副教授</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt"> 杨朝晖、</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">刘忠晖</span></p>
                                </td>
                            </tr>
                            <tr style="height:34.2pt">
                                <td rowspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月</span><span
                                            style="font-family:仿宋; font-size:10.5pt">12</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">（星期一）</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">彰显课程育人的课堂改革</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">李铁安</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">中国教科院</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">教学设计的要素与重难点</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">王瑞霖</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">首都师范大学</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="4"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月</span><span
                                            style="font-family:仿宋; font-size:10.5pt">13</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">（星期二）</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">统编教材的研制背景和价值意义</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">何源</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">教育部教材局</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="3"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">语文学科：语文统编教材的教与学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">张彬福</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">首都师范大学教师教育学院教授</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">历史学科：构建有意义的历史课堂学习</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">杨朝晖</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">首都师范大学教师教育学院教授</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">道法学科：做一名什么样的政治教师</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">王苹</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">北京陈经伦中学</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="6"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月1</span><span
                                            style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">（</span><span
                                            style="font-family:仿宋; font-size:10.5pt">星期三</span><span
                                            style="font-family:仿宋; font-size:10.5pt">）</span></p>
                                </td>
                                <td rowspan="5"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">小学语文：小学语文统编教材教学建议</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">专题讲座</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">案例教学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">徐轶</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">人民教育出版社小语室主任、统编语文教材编写组成员</span>
                                    </p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">初中语文：怎样做好初中语文教学的学业评价</span>
                                    </p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">专题讲座</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">案例教学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">孙素英</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">首都师范大学教师教育学院教授</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">历史：历史教学设计的若干问题</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">专题讲座</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">案例教学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">孙楠</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">小学道法：开展探究学习 
                                            提升道德与法治课的育人功能</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">专题讲座</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">案例教学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">姚春平</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">北京丰台区小学道法教研员</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">初中道法：初中道法教学建议及课例分析</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">专题讲座</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">案例教学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">丁丽敏</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">丰台二中</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">参观</span><span
                                            style="font-family:仿宋; font-size:10.5pt">国家</span><span
                                            style="font-family:仿宋; font-size:10.5pt">博物馆</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">实践活动</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">项目组</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="6"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月1</span><span
                                            style="font-family:仿宋; font-size:10.5pt">5</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">（</span><span
                                            style="font-family:仿宋; font-size:10.5pt">星期四</span><span
                                            style="font-family:仿宋; font-size:10.5pt">）</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">语文入校观课议课</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">小学语文：授课教师周生利。</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">授课题目：《你要是在野外迷了路》</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">初中语文：授课教师李蕾北京五中分校。</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">授课题目：阅读</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">初中语文：授课教师汪烨</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">授课题目：写作</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">实践活动</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">首师附房山实验学校</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">历史学科分组做教学设计</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">工作坊研修</span></p>
                                </td>
                                <td rowspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">自主研修</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">道法分学科分组做教学设计</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">工作坊研修</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">历史、道法入校观课议课</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; widows:0"><span
                                            style="font-family:仿宋; font-size:10.5pt">历史：房山实验学校出两位教师进行研究课：初一：《明朝对外关系》初三：专题复习课《党史百年》带队：姚岚老师</span>
                                    </p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; widows:0"><span
                                            style="font-family:仿宋; font-size:10.5pt">小学道法：“吃饭有讲究”，张琦，北京景山学校</span>
                                    </p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; widows:0"><span
                                            style="font-family:仿宋; font-size:10.5pt">初中道法：“青春有格”，裴迎冬，中国人民大学附属中学航天城分校</span>
                                    </p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">经验分享</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">互动交流</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">首师附房山实验学校</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">小学语文学科、分学段做教学设计</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">工作坊研修</span></p>
                                </td>
                                <td rowspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">自主研修</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">初中语文学科、分学段做教学设计</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">工作坊研修</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="7"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月</span><span
                                            style="font-family:仿宋; font-size:10.5pt">16</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">（</span><span
                                            style="font-family:仿宋; font-size:10.5pt">星期五</span><span
                                            style="font-family:仿宋; font-size:10.5pt">）</span></p>
                                </td>
                                <td rowspan="3"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">语文统编教材教学中的方法与策略</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">朱于国</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">人民教育出版社中语室主任、统编语文教材编写组成员</span>
                                    </p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">历史统编教材的教与学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:top; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">徐蓝</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span
                                            style="font-family:仿宋; font-size:10.5pt">首都师范大学历史文化学院教授、教育部历史课程标准修订组组长</span>
                                    </p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span
                                            style="width:259.5pt; text-indent:0pt; display:inline-block; -aw-tabstop-align:left; -aw-tabstop-pos:259.5pt"></span>
                                    </p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">道法统编教材的教与学</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">主题报告</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">朱明光</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="4"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">小学语文学科做教学设计汇报及点评</span></p>
                                </td>
                                <td rowspan="4"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">工作坊研修</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">李怀源</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">初中语文做教学设计汇报及点评</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">孙素英</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">历史学科做教学设计汇报及点评</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">姚岚</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">孙楠</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">道法学科做教学设计汇报及点评</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">刘忠晖</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">张晓龙</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月</span><span
                                            style="font-family:仿宋; font-size:10.5pt">17</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">（</span><span
                                            style="font-family:仿宋; font-size:10.5pt">星期六</span><span
                                            style="font-family:仿宋; font-size:10.5pt">）</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">全天</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; widows:0"><span
                                            style="font-family:仿宋; font-size:10.5pt">1、集中培训总结与下一步研修计划说明</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">2</span><span
                                            style="font-family:仿宋; font-size:10.5pt">、实践活动</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">总结交流</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">实践活动</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt"> </span><span
                                            style="font-family:仿宋; font-size:10.5pt"> </span><span
                                            style="font-family:仿宋; font-size:10.5pt">项目组专家</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td rowspan="2"
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:53.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">4</span><span
                                            style="font-family:仿宋; font-size:10.5pt">月</span><span
                                            style="font-family:仿宋; font-size:10.5pt">1</span><span
                                            style="font-family:仿宋; font-size:10.5pt">8</span><span
                                            style="font-family:仿宋; font-size:10.5pt">日</span></p>
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">上午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">参观首都博物馆</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                            </tr>
                            <tr style="height:27.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:31.75pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">下午</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:207.9pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:justify; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">返程</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:60.05pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:5.03pt; padding-right:5.03pt; vertical-align:middle; width:75.25pt">
                                    <p style="line-height:16pt; margin:0pt; orphans:0; text-align:center; widows:0">
                                        <span style="font-family:仿宋; font-size:10.5pt">&#xa0;</span></p>
                                </td>
                            </tr>
                            <tr style="height:0pt">
                                <td style="width:64.55pt; border:none"></td>
                                <td style="width:42.55pt; border:none"></td>
                                <td style="width:218.7pt; border:none"></td>
                                <td style="width:70.85pt; border:none"></td>
                                <td style="width:86.05pt; border:none"></td>
                            </tr>
                        </table>
                    </div>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc18850"></a><a name="_Toc7189"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（六）组织实施单位</span><span
                                style="-aw-bookmark-end:_Toc18850"></span></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">北京乐智启航文化发展有限公司（首都师范大学教育类社会服务企业，“首都教育远程互助工程”首都师范大学“双优云桥”项目主体实施单位）</span><span
                            style="font-family:宋体; font-size:12pt">；</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:normal">首都师范大学京疆学院；</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc18621"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">二、项目实施情况</span></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc29263"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（一）学员报到，项目开班</span></a>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:12pt">4</span><span
                            style="font-family:宋体; font-size:12pt">月</span><span
                            style="font-family:Calibri; font-size:12pt">10</span><span
                            style="font-family:宋体; font-size:12pt">日，来自</span><span
                            style="font-family:宋体; font-size:12pt">内蒙古四个地区的参训</span><span
                            style="font-family:宋体; font-size:12pt">教师陆续来到</span><span
                            style="font-family:宋体; font-size:12pt">首都师范大学北一校区</span><span
                            style="font-family:宋体; font-size:12pt">进行报到，领取培训资料，了解培训安排。</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:12pt">4</span><span
                            style="font-family:宋体; font-size:12pt">月</span><span
                            style="font-family:Calibri; font-size:12pt">11</span><span
                            style="font-family:宋体; font-size:12pt">日，在</span><span
                            style="font-family:宋体; font-size:12pt">首都师范大学本部第二教学楼</span><span
                            style="font-family:宋体; font-size:12pt">进行了本次培训的开班仪式</span><span
                            style="font-family:宋体; font-size:12pt">，</span><span
                            style="font-family:宋体; font-size:12pt">仪式上对项目进行详细讲解，如</span><span
                            style="font-family:宋体; font-size:12pt">培养的模式、本阶段重点工作和目标、考评标准与具体措施等，帮助参训教师明确了任务与目标</span><span
                            style="font-family:宋体; font-size:12pt">。</span><span
                            style="font-family:宋体; font-size:12pt">开班仪式由双优云桥项目组杜宇老师主持，首都师范大学领导、内蒙古师范大学继续教育学院院长呼努斯图、带队老师娜仁校长出席并讲话，</span><span
                            style="font-family:宋体; font-size:12pt">来自来自</span><span
                            style="font-family:宋体; font-size:12pt">内蒙古四个地区的</span><span
                            style="font-family:Calibri; font-size:12pt">143</span><span
                            style="font-family:宋体; font-size:12pt">名教师，共同出席了开班仪式</span><span
                            style="font-family:宋体; font-size:12pt">。</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">开班仪式的顺利召开，标志着培训研修正式拉开帷幕，领导们殷切的希望与</span><span
                            style="font-family:宋体; font-size:12pt">鼓励，帮助学员们提振了信心、点燃了热情。学员们怀着坚定的学习意愿与信念，迅速进入了学习状态，为培训的成功实施奠定了坚实基础。</span>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/01.jpeg" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">(项目开班)</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc32614"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（二）专题讲座，对话专家</span></a>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">开班仪式结束之后，就进入正式学习阶段，结合此次培训的主题和目标以及参训教师的特点，</span><span
                            style="font-family:宋体; font-size:12pt">邀请了教育部教材局、国家“三科统编教材”编写组成员、课程标准修订组成员及京津冀蒙等地优秀一线教师、教研员组成专家团队，聚焦教学典型问题，阐释“三科统编教材”课程标准的理念、内容结构，“三科统编教材”的编写背景、价值意义与教学理念，“三科统编教材”的设计思路、体例特点与内容结构。尤其关注“三科统编教材”的教学重难点、教学方法</span><span
                            style="font-family:宋体; font-size:12pt">。</span><span
                            style="font-family:宋体; font-size:12pt">让学员了解理论与实践的前沿进展</span><span
                            style="font-family:宋体; font-size:12pt">，启迪新思维、新知识、新方法，树立先进的教育教学与管理理念，拓展专业视野和增进专业理解，深化实施教育改革创新的的责任感、使命感与紧迫感。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc12648"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/02.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（专题讲座--部分）</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（三）互动交流，答疑解惑</span><span
                            style="-aw-bookmark-end:_Toc12648"></span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">项目学习中，参训教师们</span><span
                            style="font-family:宋体; font-size:12pt">不仅</span><span
                            style="font-family:宋体; font-size:12pt">是知识的接受者</span><span
                            style="font-family:宋体; font-size:12pt">，也是</span><span
                            style="font-family:宋体; font-size:12pt">思考者和提问</span><span
                            style="font-family:宋体; font-size:12pt">者，老师们一边学习一边思考，</span><span
                            style="font-family:宋体; font-size:12pt">在互动提问环节，更是积极发言参与互动交流，渴望能学到更多、更深；专家针对老师们的提问，有问必答，并结合提问老师实际情况提出针对性的解决方法。既传播知识，又答疑解惑。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/03.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/04.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                            style="font-family:宋体; font-size:10.5pt; font-weight:bold">老师与学员互动</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">--部分）</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt 0pt 0pt 24.05pt; orphans:0; text-align:justify; widows:0">
                        <a name="_Toc27520"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（四）入校访学，课例观摩</span></a>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">4</span><span
                            style="font-family:宋体; font-size:12pt">月</span><span
                            style="font-family:宋体; font-size:12pt">15</span><span
                            style="font-family:宋体; font-size:12pt">日，</span><span
                            style="font-family:宋体; font-size:12pt">三个学科的</span><span
                            style="font-family:宋体; font-size:12pt">参训老师们集体来到</span><span
                            style="font-family:宋体; font-size:12pt">首都师范大学附属实验房山学校（竹园街校区）</span><span
                            style="font-family:宋体; font-size:12pt">进行</span><span
                            style="font-family:宋体; font-size:12pt">观摩</span><span
                            style="font-family:宋体; font-size:12pt">学习。</span><span
                            style="font-family:宋体; font-size:12pt">课例观摩分学科进行，</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">小学语文学科的周生利老师为大家呈现了《你要是在野外迷了路》；初中语文学科的李蕾和汪烨两位老师分别为大家展示了阅读和写作课程。</span><span
                            style="font-family:宋体; font-size:12pt">授课结束之后三位老师做了简短的说课
                            ，解读课堂教学设计理念，阐释教法和学法。之后，</span><span
                            style="font-family:宋体; font-size:12pt">景文忠校长和</span><span
                            style="font-family:宋体; font-size:12pt">蔡可院长</span><span
                            style="font-family:宋体; font-size:12pt">分别</span><span
                            style="font-family:宋体; font-size:12pt">对三节课分别做了精彩点评。蔡院长明确指出：语文教材中的单元导语对激发学生学习兴趣以及提升学生阅读和语言能力等方面有着重要作用。在实际教学中，任课教师要最大限度发挥出单元导语的用途，从而培养学生各项语文能力以及核心素养。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">历史学科的姚岚老师带领团队为大家呈现了《明朝对外关系》、专题复习课《党史百年》；小学道法的张琦老师为大家呈现了《吃饭有讲究》；初中道法的裴迎冬老师为大家呈现了《青春有格》。授课结束后，作课教师从课堂掌控以及知识点的渗透方面与参训教师们进行了答疑互动。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">通过分学科的课例观摩，老师们进行观课、议课，亲身体验了北京一线名师的风采，也深深感受到在教学过程当中，既要根据教师的实际出发，又要联系学段与地区学情，进行合理的教学设计，提升学生思想认识，做到知行合一。</span>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/05.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（语文学科课例观摩）</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:10.5pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/06.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                            style="font-family:宋体; font-size:10.5pt; font-weight:bold">道法与历史学科课例观摩</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">）</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/07.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（景文忠校长和蔡可院长点评）</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt 0pt 0pt 24.05pt; orphans:0; text-align:justify; widows:0">
                        <a name="_Toc30993"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（五）交流研讨，同伴互助</span></a>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">本次培训，</span><span
                            style="font-family:宋体; font-size:12pt">三个学科</span><span
                            style="font-family:宋体; font-size:12pt">共计</span><span
                            style="font-family:Calibri; font-size:12pt">143</span><span
                            style="font-family:宋体; font-size:12pt">名参训教师，</span><span
                            style="font-family:宋体; font-size:12pt">按照学科和地区分布</span><span
                            style="font-family:宋体; font-size:12pt">分为</span><span
                            style="font-family:Calibri; font-size:12pt">20</span><span
                            style="font-family:宋体; font-size:12pt">个小组，同时配有班长、组长。培训</span><span
                            style="font-family:宋体; font-size:12pt">中</span><span
                            style="font-family:宋体; font-size:12pt">，项目组安排了小组培训任务，各组在组长的带领下，组员们带着任务去学习，激发了参训学员的主体性与参与性，促进了学员之间的相互交流。</span><span
                            style="font-family:宋体; font-size:12pt">老师们</span><span
                            style="font-family:宋体; font-size:12pt">通过分组研修、经验分享、互动交流等个性化、针对性培训环节，实现了从输入到输出的能力提升。</span>
                    </p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/08.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（分组研讨）</span><a
                            name="_Toc22596"></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">（六）参观博馆，铭记历史</span><span
                            style="-aw-bookmark-end:_Toc22596"></span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:normal">   
                            除了研修学习，项目组还带领老师们参观了国家博物馆和首都博物馆，两馆均历史与艺术并重，集收藏、展览、研究、考古、教育、文化交流于一体的综合性博物馆。参观过程中老师们既领略了历史的变迁，感受到了文化的熏陶，也明白了肩上的责任与重担。历史不能忘记，未来需要培养更多合格的接班人与创造者。</span>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/09.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（参训老师排队进馆）</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/10.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（参训老师参观博物馆）</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/11.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（参训老师合影</span><span
                            style="font-family:Calibri; font-size:10.5pt; font-weight:bold">--</span><span
                            style="font-family:宋体; font-size:10.5pt; font-weight:bold">部分）</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc12453"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">三、项目完成情况</span></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc23688"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（一）严格筹备准备，确保培训顺利实施</span></a>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">为了确保培训的顺利开展，</span><span
                            style="font-family:宋体; font-size:12pt">2021年4月10日，</span><span
                            style="font-family:宋体; font-size:12pt">召开</span><span
                            style="font-family:宋体; font-size:12pt">内蒙古自治区“三科”统编教材中部片区教师研修工作推进会及来京研修启动会，</span><span
                            style="font-family:宋体; font-size:12pt">会上首都师范大学</span><span
                            style="font-family:宋体; font-size:12pt">人工智能教育研究院常务副院长蔡可、首师科技教育培训中心副主任李佳健、内蒙古师范大学继续教育学院院长呼努斯图、天津师范大学中小学教师继续教育中心常务副主任李茜、河北师范大学教师教育学院院长田宝军及首都师范大学“双优云桥”项目团队参加了工作会议。会议由培训中心副主任李佳健主持。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">蔡可副院长就项目背景、已经开展的工作、后续的工作的方案及计划进行了介绍。各高校负责人对上述工作进行了深入交流，并</span><span
                            style="font-family:宋体; font-size:12pt">就“三科统编教材”教师研修的整体规划、责任分工、“一校一方案、一师一计划”、对接日常教学的一年专业支持计划形成可行性方案</span><span
                            style="font-family:宋体; font-size:12pt">。</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/12.jpeg" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">在教育部、校党委的高度重视和领导下，由教师教育学院（人工智能教育研究院）、京疆学院、首师科技教育培训中心牵头，学校办公室、保卫处、财务处、后勤集团、数字校园建设中心、公共资源服务中心、继续教育学院、</span><span
                            style="font-family:宋体; font-size:12pt">初等教育学院、</span><span
                            style="font-family:宋体; font-size:12pt">出版社、</span><span
                            style="font-family:宋体; font-size:12pt">首师国文教育科技发展中心有限责任公司</span><span
                            style="font-family:宋体; font-size:12pt">、北京乐智启航文化发展有限公司等单位</span><span
                            style="font-family:宋体; font-size:12pt">以高度的政治站位和责任担当，</span><span
                            style="font-family:宋体; font-size:12pt">通力合作，</span><span
                            style="font-family:宋体; font-size:12pt">三天时间内加班加点完成了入校研修的全部准备工作。</span><span
                            style="font-family:宋体; font-size:12pt">在资金没有到位的情况下，继续教育学院和“双优云桥”</span><span
                            style="font-family:宋体; font-size:12pt">项目团队积极</span><span
                            style="font-family:宋体; font-size:12pt">筹措资金，保证了内蒙古自治区</span><span
                            style="font-family:宋体; font-size:12pt">“三科”统编教材中部片区教师研修培训于4月11日顺利开班，</span><span
                            style="font-family:宋体; font-size:12pt">让</span><span
                            style="font-family:宋体; font-size:12pt">来自</span><span
                            style="font-family:宋体; font-size:12pt">呼和浩特市、包头市、乌兰察布市、锡林郭勒盟143名民族语言教师将在获得知识的同时，感受到首都及首都师范大学的温暖。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc13566"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（二）结合实际制定方案，针对性培训</span></a>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">本次集中面授培训邀请了教育部教材局、国家“三科统编教材”编写组成员、课程标准修订组成员及京津冀蒙等地优秀一线教师、教研员组成专家团队，聚焦教学典型问题，阐释“三科统编教材”课程标准的理念、内容结构，“三科统编教材”的编写背景、价值意义与教学理念，“三科统编教材”的设计思路、体例特点与内容结构。尤其关注“三科统编教材”的教学重难点、教学方法；授课专家与学员一同探讨如何做好教学设计，并就具体的课堂教学案例进行分析。此外，还安排了入校观课、听评课等实践任务，开展高水平、有针对性的培训。</span><span
                            style="font-family:宋体; font-size:12pt">提高培训的针对性、实用性、可借鉴性，保障参训教师需有所学，学以致用，用有提升。</span>
                    </p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><a
                            name="_Toc26025"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（三）落实保障，生活学习两手抓</span></a>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">在教育部、校党委的高度重视和领导下，由教师教育学院（人工智能教育研究院）、京疆学院、首师科技教育培训中心牵头，学校办公室、保卫处、财务处、后勤集团、数字校园建设中心、公共资源服务中心、继续教育学院、初等教育学院、出版社、首师国文教育科技发展中心有限责任公司、北京乐智启航文化发展有限公司等单位以高度的政治站位和责任担当，通力合作，</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">共同为参训学员参训期间的学习和生活提供有力保障，不仅要使每一位参训教师都学有所获，也要保障老师们学习期间生活顺心和便利</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">。</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt 0pt 0pt 21pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/13.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（参训老师食宿情况）</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc31282"><span style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">四</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">小组任务驱动，同伴互助合作学习</span></a>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:normal">本次培训，</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">三个学科</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">共计</span><span
                            style="font-family:Calibri; font-size:12pt; font-weight:normal">143</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">名参训教师，</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">按照学科和地区分布</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">分为</span><span
                            style="font-family:Calibri; font-size:12pt; font-weight:normal">20</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">个小组，同时配有班长、组长。培训</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">中</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">，项目组安排了小组培训任务，各组在组长的带领下，组员们带着任务去学习，激发了参训学员的主体性与参与性，促进了学员之间的相互交流。</span><span
                            style="font-family:Calibri; font-size:12pt; font-weight:normal">4</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">月</span><span
                            style="font-family:Calibri; font-size:12pt; font-weight:normal">16</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">日进行了</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">分组汇报，各组选派代表依次进行任务成果展示，梳理组内学习情况，总结培训收获，畅谈运用所学知识在今后教师工作中的改进计划，最后班长对本班的培训情况进行了总结。</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">老师们</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">通过</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">学习</span><span
                            style="font-family:宋体; font-size:12pt; font-weight:normal">、经验分享、互动交流等个性化、针对性培训环节，实现了从输入到输出的能力提升。</span>
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/14.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:2.4pt 0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（分组汇报）</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <a name="_Toc2799"><span style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">五</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">结业仪式</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">，</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">展示收获，寄望未来</span></a></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:21pt"><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">4月16日</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">，139名学员提交了教学设计与教学感悟，20位教师进行了有专家参与指导的分享展示课与说课，圆满完成了培训任务。</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">当日项目组</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">为参训学员举行了结业典礼，校人工智能教育研究院副院长蔡可、首师科技教育培训中心副主任李佳健、内蒙古师范大学继续教育学院院长呼努斯图、内蒙古呼和浩特市民族实验学校副校长娜仁格日勒以及来自呼和浩特市、包头市、乌兰察布市、锡林郭勒盟的143位</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">三科线</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">教师</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">参加了结业典礼</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">。</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/15.jpeg" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt; font-weight:bold">(项目结业)</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">蔡可对本次培训进行了全面总结并对下一阶段的培训工作做了初步规划。他指出，统编教材容量大、理念新，将新的课程标准与理念融入实际教育教学当中任重而道远。下一步，我校将按照教学资源配置、线上研修同步、线上线下一体化融合的步骤继续为内蒙教师提供源源不断的帮助。呼努斯图向我校表示诚挚感谢并对教师们提出新的期望与寄托，希望参训教师将学习到的新理念、新方法、新技术带回内蒙当地，传承经验，带动起更多的种子教师，为民族教育事业贡献力量。娜仁格日勒代表参训教师发言。她表示，首师大强大的师资团队通过理论与实践的结合，让内蒙教师拓宽了视野、开拓了思路、明确了方向、拥有了动力，也在春暖花开的首师大重新感受到青春的气息和向上的力量。李佳健作会议总结发言。他提出，下一阶段内蒙中部片区研修工作将抓紧落实“专家引领-师范生协助-内蒙教师提升”学习共同体的打造，也将探索“做中学”“做中研”“双师课堂”等研修模式，为内蒙教师用好三科统编教材、教好三科统编教材披荆斩棘、开渠引路。</span>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; text-indent:27pt"><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">结业典礼结束后，“双优云桥”项目团队带领学员参观了首都博物馆。至此，内蒙古中部四盟市三科统编教材教师</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">第一期线下</span><span
                            style="background-color:#ffffff; font-family:宋体; font-size:12pt">培训在洋溢着首都文化气息与徜徉着北京历史长河的氛围中落下帷幕。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc26590"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">四、项目效益</span></a></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">本次培训在</span><span
                            style="font-family:宋体; font-size:12pt">在教育部、校党委的高度重视和领导下，由教师教育学院（人工智能教育研究院）、京疆学院、首师科技教育培训中心牵头，学校办公室、保卫处、财务处、后勤集团、数字校园建设中心、公共资源服务中心、继续教育学院、</span><span
                            style="font-family:宋体; font-size:12pt">初等教育学院、</span><span
                            style="font-family:宋体; font-size:12pt">出版社、</span><span
                            style="font-family:宋体; font-size:12pt">首师国文教育科技发展中心有限责任公司</span><span
                            style="font-family:宋体; font-size:12pt">、北京乐智启航文化发展有限公司等单位</span><span
                            style="font-family:宋体; font-size:12pt">的</span><span
                            style="font-family:宋体; font-size:12pt">支持下，在参训老师和项目组共同的努力下，来自</span><span
                            style="font-family:宋体; font-size:12pt">内蒙古中部地区的143位</span><span
                            style="font-family:宋体; font-size:12pt">教师圆满完成了学习任务，为本次培训交出了一份令人满意的答卷。</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc29969"><span style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">一</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">培训内容符合当前教育发展新形势</span></a>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">本次培训在内容安排上注重</span><span
                            style="font-family:宋体; font-size:12pt">课程的系统性，</span><span
                            style="font-family:宋体; font-size:12pt">强化</span><span
                            style="font-family:宋体; font-size:12pt">培训主题的聚焦性，针对学科</span><span
                            style="font-family:宋体; font-size:12pt">教学</span><span
                            style="font-family:宋体; font-size:12pt">的关键问题</span><span
                            style="font-family:宋体; font-size:12pt">进行</span><span
                            style="font-family:宋体; font-size:12pt">了系统梳理</span><span
                            style="font-family:宋体; font-size:12pt">，</span><span
                            style="font-family:宋体; font-size:12pt">聚焦教学典型问题，阐释“三科统编教材”课程标准的理念、内容结构，“三科统编教材”的编写背景、价值意义与教学理念，“三科统编教材”的设计思路、体例特点与内容结构。尤其关注“三科统编教材”的教学重难点、教学方法；授课专家将与学员一同探讨如何做好教学设计，并就具体的课堂教学案例进行分析。此外，还安排了入校观课、听评课等实践任务，以期开展高水平、有针对性的培训。</span><span
                            style="font-family:宋体; font-size:12pt">与</span><span
                            style="font-family:宋体; font-size:12pt">统编三科</span><span
                            style="font-family:宋体; font-size:12pt">教师</span><span
                            style="font-family:宋体; font-size:12pt">的培训目标</span><span
                            style="font-family:宋体; font-size:12pt">相对应，充分体现了学科</span><span
                            style="font-family:宋体; font-size:12pt">教师在新形势下应具备的</span><span
                            style="font-family:宋体; font-size:12pt">关键</span><span
                            style="font-family:宋体; font-size:12pt">能力，与教育改革发展的形势要求</span><span
                            style="font-family:宋体; font-size:12pt">相</span><span
                            style="font-family:宋体; font-size:12pt">符合。</span>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc11562"><span style="font-family:宋体; font-size:12pt; font-weight:bold">（</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">二</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">）</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">培训形式以任务为驱动，激发教师参与热情</span></a>
                    </p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:宋体; font-size:12pt">培训形式上注重</span><span
                            style="font-family:宋体; font-size:12pt">教师具体学习任务的完善，</span><span
                            style="font-family:宋体; font-size:12pt">以</span><span
                            style="font-family:宋体; font-size:12pt">任务</span><span
                            style="font-family:宋体; font-size:12pt">为</span><span
                            style="font-family:宋体; font-size:12pt">驱动激发</span><span
                            style="font-family:宋体; font-size:12pt">教师</span><span
                            style="font-family:宋体; font-size:12pt">学习的积极性和主动性。</span><span
                            style="font-family:宋体; font-size:12pt">在每一个课程</span><span
                            style="font-family:宋体; font-size:12pt">模块中都设计了不同的培训</span><span
                            style="font-family:宋体; font-size:12pt">组织</span><span
                            style="font-family:宋体; font-size:12pt">形式，有专题讲座，也有</span><span
                            style="font-family:宋体; font-size:12pt">课例观摩、分组研讨、</span><span
                            style="font-family:宋体; font-size:12pt">听评课等</span><span
                            style="font-family:宋体; font-size:12pt">参与式培训。</span><span
                            style="font-family:宋体; font-size:12pt">每个模块</span><span
                            style="font-family:宋体; font-size:12pt">的参与式培训</span><span
                            style="font-family:宋体; font-size:12pt">也</span><span
                            style="font-family:宋体; font-size:12pt">不相同，案例分析</span><span
                            style="font-family:宋体; font-size:12pt">、</span><span
                            style="font-family:宋体; font-size:12pt">听评课</span><span
                            style="font-family:宋体; font-size:12pt">、</span><span
                            style="font-family:宋体; font-size:12pt">经验分享</span><span
                            style="font-family:宋体; font-size:12pt">、</span><span
                            style="font-family:宋体; font-size:12pt">活动实践</span><span
                            style="font-family:宋体; font-size:12pt">等多种形式相结合</span><span
                            style="font-family:宋体; font-size:12pt">，</span><span
                            style="font-family:宋体; font-size:12pt">促使学员深入反思及内化</span><span
                            style="font-family:宋体; font-size:12pt">改进。</span><span
                            style="font-family:宋体; font-size:12pt">在</span><span
                            style="font-family:宋体; font-size:12pt">增强培训的吸引力</span><span
                            style="font-family:宋体; font-size:12pt">的同时</span><span
                            style="font-family:宋体; font-size:12pt">，</span><span
                            style="font-family:宋体; font-size:12pt">加强</span><span
                            style="font-family:宋体; font-size:12pt">教师参与度，达到以任务带队伍的目的。</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p
                        style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc17078"><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">（三）</span><span
                                style="font-family:宋体; font-size:12pt; font-weight:bold">项目培训反馈</span></a><span
                            style="font-family:宋体; font-size:12pt; font-weight:bold"> </span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-indent:21pt; widows:0">
                        <span
                            style="font-family:宋体; font-size:12pt">本次培训注重实践取向，针对问题解决，着眼行为改进。通过培训给予学员与专家零距离交流的体验，以研讨、交流、展示、分享的方式体验了参与式培训。同时深入</span><span
                            style="font-family:宋体; font-size:12pt">北京</span><span
                            style="font-family:宋体; font-size:12pt">市名校进行考察学习，其收获远远超出单一的课程理论的学习，因此，教师对此次培训的评价极高。在培训结束前我们</span><span
                            style="font-family:宋体; font-size:12pt">随机对部分参训教师</span><span
                            style="font-family:宋体; font-size:12pt">进行了</span><span
                            style="font-family:宋体; font-size:12pt">访问</span><span
                            style="font-family:宋体; font-size:12pt">，</span><span
                            style="font-family:宋体; font-size:12pt">以便</span><span
                            style="font-family:宋体; font-size:12pt">了解学员对此次培训的</span><span
                            style="font-family:宋体; font-size:12pt">反馈</span><span
                            style="font-family:宋体; font-size:12pt">。</span><span
                            style="font-family:宋体; font-size:12pt">参与访问的老师均对此次培训做出了高度评价。</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:200%; margin:0pt; text-align:justify; text-indent:27pt">
                        <span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">“在这次的培训中，我们确实收获很多，无论从教学设计还是教学理念方面，都突破了我们原来的局限，对我们后期的统编教材教学影响是深远的，我对未来的教学有信心了。”</span>
                    </p>
                    <p style="font-size:12pt; line-height:200%; margin:0pt; text-align:right"><span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">——</span><span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">内蒙古自治区锡林郭勒盟镶黄旗</span><span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">--</span><span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">安格尔</span><span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">（</span><span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">道法</span><span
                            style="background-color:#ffffff; font-family:仿宋; font-size:12pt">）</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; text-indent:21pt; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
                            style="font-family:Calibri; font-size:12pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:宋体; font-size:12pt">&#xa0;</span></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        watch: {},
        methods: {
            // 	handle(groupId) {
            //     this.$router.push({path:"/resourcescenter",query:{type:'teacher',groupId:groupId}});
            // },
            handle(groupId) {
                this.$router.push({
                    path: "/resources",
                    // query: { type: "yin",groupId:groupId },
                });
            },
        },
        mounted() {},
        components: {},
    };
</script>

<style lang="less" scoped>
    .resource-center-preview {
        min-height: 100vh;
        min-width: 990px;

        @media screen and (min-width:1250px) {
            min-width: 1200px;
        }

        .tabs-box {
            p {
                font-family: Source Han Sans CN !important;
            }

            span {
                font-family: Source Han Sans CN !important;
            }

            display: flex;
            justify-content: space-between;
            // height: 80vh;
            width: 990px;
            margin: 0 auto;

            @media screen and (min-width:1250px) {
                min-width: 1200px;
            }

            .tabs-left {
                border: 1px solid #f8f8f8;
                // box-shadow: 0px 5px 29px 0px rgb(22 115 255 / 16%);
                border-radius: 2px;
                width: 200px;
                padding: 20px 40px;
                font-size: 14px;
                height: 80vh;
                overflow-y: auto;
                background: #fafafa;


            }

            .tabs-right {
                width: 100%;
                padding: 20px 40px;
                margin-left: 20px;
                background: #fafafa;
                flex: 1;
                border: 1px solid #f8f8f8;
                border-radius: 2px;
                height: 80vh;
                overflow-y: auto;

                // width: 200px;
                .font-weight {
                    font-family: 仿宋;
                    color: red;
                    font-weight: bold;
                    font-size: 16pt;
                    cursor: pointer;
                }

            }
        }
    }
</style>